$c-primary: #7615c6;
$c-secandary: #7615c6;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;
$heading-color-overrided: #7615c6;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.quickAddButtonGroup {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;

    .quickAddButton {
        margin-right: 0.5rem;
    }
}